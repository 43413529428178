<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Informe fotográfico</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-8" style="display: grid;row-gap: 15px; height: fit-content;">
                    <div class="card card-primary">
                        <div class="card-header">
                            <div ><span>Generar informe</span></div>
                        </div>
                        <div class="card-body" style="text-align: center;">
                            <div class="card" v-for="visita in Object.entries(visitas)" :key="visita[0]">
                                <div class="card-header">
                                    {{ visita[0] }}
                                    <input  style="margin-left: 10px;" type="checkbox" @change="cambioActivado(visita)" v-model="activadoV[visita[0]]">
                                </div>
                                <div class="card-body">
                                    <div class="row" style="justify-content: space-around;row-gap: 10px;">
                                        <div v-for="img in Object.entries(visita[1])" :key="img[1].id_documento">
                                            <div style="display: grid; row-gap: 10px;">
                                                <Image v-if="img[1].propiedad_cliente != 0 && urlglobal != 'null.pwgs.app'"  :src='img[1].url_completa_descarga' style="border: 5px solid goldenrod;" width="100" preview :alt="img[1].descripcion" />
                                                <Image v-else-if="urlglobal != 'null.pwgs.app'"  :src='img[1].url_completa_descarga'  width="100" preview :alt="img[1].descripcion" />
                                                <Image v-if="img[1].propiedad_cliente != 0 && urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + img[1].documento + "&servicio=" +id' style="border: 5px solid goldenrod;" width="100" preview :alt="img[1].descripcion" />
                                                <Image v-else-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + img[1].documento + "&servicio=" +id' width="100" preview :alt="img[1].descripcion" />
                                                <div v-if="parametros.campos_obligatorios.antes_despues == 1 && img[1].cuando==''" style="text-align: center;">
                                                    <select v-model="tiempoImagenSelect[img[1].id_documento]" id="tiempo">
                                                        <option value="">Selecciona tiempo</option>
                                                        <option value="Antes">Antes</option>
                                                        <option value="Despues">Después</option>
                                                    </select>
                                                    <input type="checkbox" v-model="activado[img[1].id_documento]">
                                                </div>
                                                <div v-else style="text-align: center;">
                                                    <span style="margin-right: 5px;" v-if="img[1].cuando=='despues'" class="badge bg-blue">después</span>
                                                    <span style="margin-right: 5px;" v-else class="badge bg-blue">{{img[1].cuando}}</span>
                                                    <input type="checkbox" v-model="activado[img[1].id_documento]">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                        <div style="text-align: center;">
                            <h5>Texto (opcional)</h5>
                            <textarea v-model="tatexto" id="texto" style="width: 80%;" rows="4"></textarea>
                        </div>
                    
                        <div style="text-align: center;">
                            <h5>Descripción Informe (no se muestra en el informe)</h5>
                            <textarea v-model="tadescripcion" id="descripcion" style="width: 80%;" rows="4"></textarea>
                        </div>

                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <div style="display: flex; gap: 15px;">
                                <span>Número de fotos por página</span>
                                <input max="16" type="number" style="width:50px" v-model="numfotos">
                            </div>
                            <div style="display: flex; gap: 15px;">
                                <span>Apaisado</span>
                                <input  type="checkbox" v-model="apaisado">
                            </div>
                            <div style="display: flex; gap: 15px;">
                                <span>Mostrar logo</span>
                                <input  type="checkbox" v-model="logo">
                            </div>
                            <div style="display: flex; gap: 15px;">
                                <span>Mostrar expediente</span>
                                <input  type="checkbox" v-model="expediente">
                            </div>
                            <div style="display: flex; gap: 15px;">
                                <span>Mostrar descripciones</span>
                                <input  type="checkbox" v-model="descripciones">
                            </div>
                            <div style="display: flex; gap: 15px;" >
                                <span>Seleccionar todas/ninguna</span>
                                <input  @click="cambiotodas" type="checkbox" v-model="todasninguna">                                
                            </div>
                        </div>
                        <div style="text-align: end; margin: 15px;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey; grid-area: 15px;" @click="generarConsulta">Generar</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-primary">
                        <div class="card-header">
                            Informes generados
                        </div>
                        <div  class="card-body">
                            <div class="card" v-for="informe in informes" :key="informe.id_documento" style="justify-content:space-between; display: flex; overflow: auto;flex-direction: row; border-left:6px solid #117a8b;" >
                                <div class="card-body" >
                                    <div>
                                        <span>{{ informe.fecha }}</span>
                                        <button style="margin-left: 10px;" class="btn btn-light" @click="descargar(informe.url_completa_descarga)"><i class="fas fa-file-pdf"></i></button>
                                        <div>{{ informe.descripcion }}</div>
                                        <div style="display: flex; gap: 10px;">
                                            <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="mostrarForm[informe.id_documento] = true">Enviar</button>
                                            <button class="btn btn-light btn-sm" style="border: 1px solid grey;" v-if="!informe.envios.length>0" @click="eliminarInforme(informe.id_documento)">Eliminar</button>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; gap: 15px; margin-top: 20px;" v-if="mostrarForm[informe.id_documento]">
                                        <div style="display: flex; flex-flow: row wrap; gap: 10px;">
                                            <select @change="test123($event);" class="form-control" style="flex-grow: 3; max-width: 70%;" v-model="envioSeleccionado[informe.id_documento]" id="email">
                                                <option disabled hidden value="">Seleccione</option>
                                                <option  v-for="envio in parametros.tipos" :key="Object.values(envio)[0]" :value="Object.keys(envio)[0]">{{ Object.values(envio)[0] }}</option>
                                            </select>
                                            <select  class="form-control" v-model="papSeleccionado[informe.id_documento]" style="flex-grow: 4;max-width: 70%;" v-if="parametros.tipos_pap && tipoenvio != 'email' && tipoenvio" id="pap">
                                                <option disabled hidden value="">Seleccione tipo</option>
                                                <option v-for="pap in parametros.tipos_pap" :key="Object.values(pap)[0]" :value="Object.keys(pap)[0]">{{ Object.values(pap)[0] }}</option>
                                            </select>
                                            <select class="form-control" v-model="ipasSeleccionado[informe.id_documento]" style="flex-grow: 4;max-width: 70%;" v-if="parametros.prestaciones_ipas && tipoenvio != 'email' && tipoenvio" id="ipas">
                                                <option disabled hidden value="">Seleccione tipo</option>
                                                <option v-for="ipas in parametros.prestaciones_ipas" :key="Object.values(ipas)[0]" :value="Object.keys(ipas)[0]">{{ Object.values(ipas)[0] }}</option>
                                            </select>
                                            <select class="form-control" v-model="mutuaSeleccionada[informe.id_documento]" style="flex-grow: 4;max-width: 70%;" v-if="parametros.servicios_mutua && tipoenvio != 'email' && tipoenvio" id="mutua">
                                                <option disabled hidden value="">Seleccione tipo</option>
                                                <option v-for="mutua in parametros.servicios_mutua" :key="Object.values(mutua)[0]" :value="Object.keys(mutua)[0]">{{ Object.values(mutua)[0] }}</option>
                                            </select>
                                            <select class="form-control" v-model="multiasistenciaSeleccionada[informe.id_documento]" style="flex-grow: 4;max-width: 70%;" v-if="parametros.servicios_multiasistencia && tipoenvio != 'email' && tipoenvio" id="multiasistencia">
                                                <option disabled hidden value="">Seleccione tipo</option>
                                                <option v-for="multiasistencia in parametros.servicios_multiasistencia" :key="Object.values(multiasistencia)[0]" :value="Object.keys(multiasistencia)[0]">{{ Object.values(multiasistencia)[0] }}</option>
                                            </select>
                                            <select  v-if="tipoenvio == 'email' "   class="form-control" v-model="destinoSeleccionado[informe.id_documento]" id="destino">
                                                <option disabled hidden value="">Seleccione destino</option>
                                                <option v-for="destino in parametros.emails" :key="destino.email" :value="destino.email">{{ destino.nombre }}</option>
                                            </select>
                                        </div>
                                        <input  v-if="tipoenvio == 'email'"  class="form-control" v-model="emailIntroducido[informe.id_documento]" style="max-width: 70%;" type="text" placeholder="Introducir email">
                                        <div   v-if="tipoenvio == 'email'" >
                                            <input id="check" type="checkbox" v-model="check[informe.id_documento]" style="margin-right: 15px;">
                                            <label for="check">Solo código en asunto</label>
                                        </div>
                                        <div>
                                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="enviarInforme(informe.id_documento)">Enviar</button>
                                            <button class="btn btn-sm btn-light" style="border: 1px solid grey; margin-right: 15px;" @click="mostrarForm[informe.id_documento] = false">Cerrar</button>
                                        </div>
                                    </div>
                                    <div class="table-responsive" v-if="informe.envios.length>0">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    Fecha
                                                    </th>
                                                    <th>
                                                        Tipo
                                                    </th>
                                                    <th>
                                                        Destino
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="envio in informe.envios" :key="envio.id_envio">
                                                    <td>
                                                        {{ envio.fecha_envio }}
                                                    </td>
                                                    <td>
                                                        {{ envio.tipo_envio }}
                                                    </td>
                                                    <td>
                                                        {{ envio.destino_envio }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <!--<gestordocumentos_datosadicionales :visible="visible" :idservicio="id" @update:visible="cerrarModal"/>-->
    </div>  
</template>
<script>
import Image from 'primevue/image';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            urlglobal: '',
            activado:[],
            activadoV:[],
            visitas:[],
            numfotos:4,
            apaisado:false,
            logo:true,
            expediente:true,
            descripciones:true,
            todasninguna:false,
            tatexto:'',
            tadescripcion:'',
            informes:[],
            mostrarForm:[],
            envioSeleccionado:[],
            emailIntroducido:[],
            destinoSeleccionado:[],
            text:[],
            parametros:[],
            check:[],
            tiempos:[],
            tiempoImagenSelect:[],
            papSeleccionado: [],
            tipoenvio:'',
            ipasSeleccionado:[],
            mutuaSeleccionada:[],
            multiasistenciaSeleccionada:[],
        }
    },
    components: {
        'Image': Image,
    },
        setup() {
        return;
    },
    methods: {
        mostrartipoenvio() {
            console.log("tipoenvio", this.tipoenvio);
        },
        cambiotodas(){
            if(this.todasninguna==false){
                for(var id of Object.entries(this.activado)){
                this.activado[id[0]] = true;
            }
            }else{
                for(var ids of Object.entries(this.activado)){
                this.activado[ids[0]] = false;
            }
            }
            
        },
        async eliminarInforme(id){
            const api = new PwgsApi();
            try{
                await api.delete('servicios/'+this.id+'/informes-fotograficos/'+id);
                this.cargarInformes();
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 2000 });
            }
        },
        async enviarInforme(id){
            const api = new PwgsApi();
            try{
                await api.put('servicios/'+this.id+'/enviar-informe-fotografico/'+id, {tipo:this.envioSeleccionado[id], emails:[this.emailIntroducido[id]], asunto_codigo:this.check[id]?1:0,
                     tipo_pap:this.papSeleccionado[id], prestacion_ipas:this.ipasSeleccionado[id], servicio_mutua: this.mutuaSeleccionada[id],
                      servicio_multiasistencia:this.multiasistenciaSeleccionada[id]});
                this.cargarInformes();
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
            }
        },
        descargar(link){
        //    var links = link.slice(5);
        //    let urlglobal = localStorage.getItem('direccion');
            window.open(link, '_blank');
        },
        async cargarInformes(tt){
            console.log('informesCreados', tt);
            this.informes = [];
            this.activado = [];
            this.activadoV = [];
            const api = new PwgsApi();
            const resp = await api.get('servicios/'+this.id+'/informes-fotograficos');
            this.informes = resp.informes;
            this.parametros = resp.parametros;
            for(let inf of this.informes){
                var id = inf.id_documento;
                this.mostrarForm[id]=false;
                this.emailIntroducido[id]="";
                this.envioSeleccionado[id]="";
                this.destinoSeleccionado[id]="";
                this.papSeleccionado[id]="";
                this.ipasSeleccionado[id]="";
                this.mutuaSeleccionada[id]="";
                this.multiasistenciaSeleccionada[id]="";
                this.text[id]="";
                this.check[id]=false;
            }
            console.log('informes', this.informes);
            console.log('parametros', this.parametros);
        },

        async generarConsulta(){
            var ids = {};
            try{
                for(let vis of Object.entries(this.activado)){
                    if(vis[1]){
                        var cuando = this.tiempos[vis[0]];
                        if(this.parametros.campos_obligatorios.antes_despues == 1 && cuando == ""){
                            cuando = this.tiempoImagenSelect[vis[0]];
                        }
                        ids[vis[0]]=cuando;
                    }
                }
                const api = new PwgsApi();
                await api.post('servicios/'+this.id+'/informes-fotograficos', {ids_documentos:ids, texto:this.tatexto, descripcion:this.tadescripcion,
                    n_fotos_pag:this.numfotos, orientacion: this.apaisado? 'L':'P', logo:this.logo?1:0, expediente: this.expediente?1:0,
                    muestra_descripcion: this.descripciones?1:0});
                this.$toast.add({ severity:'success', summary: 'Generado', detail: 'Informe generado correctamente', life: 2000 });
                this.cargarInformes();
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 2000 });
            }
        },
        cambioActivado(visita){
            if(!this.activadoV[visita[0]]==true){
                for(let vis of Object.entries(visita[1])){
                    var id = vis[0];
                    this.activado[id]=false;
                }
            }else{
                for(let vis2 of Object.entries(visita[1])){
                    var ids = vis2[0];
                    this.activado[ids]=true;
                }
            }
        },
        test123(event) {
            console.log("HOLAAAAAAAAAAAA", event.target.value);
            this.tipoenvio = event.target.value;
        },
        async cargarImagenes(tt){
            this.visitas=[];
            const api = new PwgsApi();
            const resp = await api.get('servicios/'+this.id+'/documentos');
            for(let [key, imagen] of Object.entries(resp)){
                if(imagen.image == '1'){
                    var visita = imagen.fecha_visita
                    if(visita == " "){
                        visita = "Sin visita";
                    }
                    if(!this.visitas[visita]){
                        this.visitas[visita]=[];
                        this.activadoV[visita]=false;
                    }
                    var id = key;
                    this.tiempos[id]=imagen.cuando;
                    this.activado[id]=false;
                    this.tiempoImagenSelect[id]="";
                    this.visitas[visita][id]=imagen;
                }
            }
            console.log('informeImagenesCargadas', this.visitas, tt);
            this.$nextTick(() => {
            this.$forceUpdate();
        });
        },
    },
    mounted() {
        this.cargarImagenes(1);
        this.cargarInformes(1);
        this.urlglobal = localStorage.getItem("direccion");
    },
    watch:{
        numfotos(value){
            if(value>16){
                this.numfotos=16;
            }
            if(value<1){
                this.numfotos=1;
            }
        },
        id(){
            this.cargarImagenes(2);
            this.cargarInformes(2);
        },
        destinoSeleccionado:{
            handler(newVal) {
                Object.keys(newVal).forEach(idDoc => {
                    this.emailIntroducido[idDoc] = newVal[idDoc];
                });
            },
            deep: true
        },
        tipoenvio(value) {
            console.log("envioseleccionado", value);
        }
    }
})
</script>
<style>
</style>